import React from 'react';
import PageContainer from "../components/common/PageContainer";
import { ExternalLink } from 'lucide-react';
import RoadmapFeatures from '../components/common/RoadmapFeatures';

export default function About() {
  return (
    <PageContainer>
      <h1 className="text-3xl font-bold mb-6">About</h1>
      <p className="mb-4">
        HyperTracker offers in-depth historical performance analytics for a curated selection of 
        <a 
          href="https://hyperliquid.xyz" 
          target="_blank" 
          rel="noopener noreferrer"
          className="text-custom-yellow hover:underline mx-1 inline-flex items-center"
        >
          Hyperliquid's 
          <ExternalLink className="ml-1 h-4 w-4" />
        </a>
        {' '}traders and vaults. The platform aims
        to provide insights into their execution processes, allowing users to learn from top performers.
      </p>
      <p className="mb-4">
        HyperTracker started as a fun side project. It began with me scraping trading data and sharing insights in threads on X. 
        Over time, I figured it made sense to automate the process, which led to building this web app to make the analysis easier and more accessible.
        <br></br><br></br>
        Please note that due to limitations in Hyperliquid’s historical data, some fills may be missing. 
        To address this, you can check the “Coverage (%)” metric in the Dashboard tables and Positions details. This metric represents the 
        percentage of positions fully supported by fill data. For example, a 90% coverage indicates that 90% of 
        positions are complete, while 10% may have missing fills.
        <br></br><br></br>
        Although still in beta, HyperTracker’s core features are ready to use. 
        I’m building in public to gather feedback and improve the platform over time. If you have any suggestions, 
        feel free to reach out on X at{' '}
        <a
          href="https://x.com/0xfab_eth"
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-400 hover:text-blue-300"
        >
          @0xfab_eth
        </a>.
      </p>
      
      <RoadmapFeatures />
    </PageContainer>
  );
}